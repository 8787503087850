<!--
  PACKAGE_NAME : src\pages\ewm\hr\agent\appointment\join.vue
  FILE_NAME : join
  AUTHOR : devyoon91
  DATE : 2024-07-15
  DESCRIPTION : 입사 관리
-->
<template>
  <div class="container">
    <div class="page_search_box line_bottom_1px">
      <div class="inner alL">
        <DxSelectBox
          styling-mode="outlined"
          ref="approvalSelectBox"
          display-expr="label"
          value-expr="value"
          :items="personnelAppointmentSelectBox"
          :value="personnelAppointmentSelectBox[0].value"
          placeholder="발령상태 선택"
          width="100px"
          class="mr-4"
          @value-changed="onPASelectBoxValueChange"
        />
        <DateRangeBox ref="dateRangeBox" label="발령 일자" :start-dt="dayStart" :end-dt="dayEnd" />
        <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
      </div>
    </div>
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    <ModalJoinAgent :is-open="modalJoinAgent.visible" @closeModal="onModalJoinAgentClose" @saveModal="onModalJoinAgentSave" />
  </div>
</template>

<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import { DxButton } from 'devextreme-vue/button';

  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import moment from 'moment/moment';
  import ModalJoinAgent from '@/pages/ewm/hr/agent/appointment/popup/modal-join-agent.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, ModalJoinAgent, DxSelectBox, DateRangeBox, DxButton },
    props: {},
    data() {
      return {
        dayStart: getPastFromToday(14, 'days'),
        dayEnd: moment().add(30, 'days').format('YYYYMMDD'),
        modalJoinAgent: {
          visible: false,
        },
        selectedPersonnelAppointmentState: this.$_enums.ewm.personnelAppointmentState.WAITING.value,
        personnelAppointmentSelectBox: this.$_enums.ewm.personnelAppointmentState.values,
        dataGrid: {
          keyExpr: 'paId', // 그리드 키값
          refName: 'joinAgentGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '신규입사', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'virtual', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: '발령 등록',
                  elementAttr: { class: 'default filled txt_XS add1 ml-1' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    this.modalJoinAgent.visible = true;
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  text: '발령 취소',
                  elementAttr: { class: 'btn_XS white light_filled ml-1' },
                  width: 100,
                  height: 30,
                  onClick: this.onCancel,
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: true, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: true, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '발령상태',
              dataField: 'paStateCd',
              alignment: 'center',
              width: 150,
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: true,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.personnelAppointmentState.values,
                valueExpr: 'value',
                displayExpr: 'label',
              },
            },
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowGrouping: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              caption: '부서',
              dataField: 'afterAgentInfo.deptCd',
              visible: true,
              alignment: 'left',
              allowEditing: false,
              lookup: {
                dataSource: [],
                valueExpr: 'codeId',
                displayExpr: 'pathNmFull',
              },
            },
            {
              caption: '직급',
              dataField: 'afterAgentInfo.jikgupCd',
              visible: true,
              alignment: 'center',
              allowEditing: false,
              lookup: {
                dataSource: [],
                valueExpr: 'codeId',
                displayExpr: 'codeNm',
              },
            },
            {
              caption: '직책',
              dataField: 'afterAgentInfo.jikweeCd',
              visible: true,
              alignment: 'center',
              allowEditing: false,
              lookup: {
                dataSource: [],
                valueExpr: 'codeId',
                displayExpr: 'codeNm',
              },
            },
            {
              caption: '업무그룹',
              dataField: 'afterAgentInfo.workgroupCd',
              visible: true,
              alignment: 'center',
              allowEditing: false,
              lookup: {
                dataSource: [],
                valueExpr: 'codeId',
                displayExpr: 'codeNm',
              },
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '입사일',
              dataField: 'baseDt',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              caption: '발령 등록 일시',
              dataField: 'regDt',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /**
       * @description : 발령상태 셀렉트박스 변경 이벤트
       * @param e
       */
      onPASelectBoxValueChange(e) {
        this.selectedPersonnelAppointmentState = e.value;
        this.onSearch();
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} [${rowData.agtid}]`;
      },
      /**
       * @description : 퇴직 발령 팝업 열기
       */
      onModalJoinAgentClose() {
        this.modalJoinAgent.visible = false;
      },
      /**
       * @description : 퇴직 발령 팝업 다음 버튼 클릭 이벤트
       * @param e
       */
      onModalJoinAgentSave(e) {
        this.modalJoinAgent.visible = false;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept,root_ewm_hr_jikgup,root_ewm_hr_jikwee,root_ewm_hr_workgroup');
      },
      /**
       * @description : 신규입사 발령 조회
       * @return {Promise<*>}
       */
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'paId',
          async load() {
            const payload = {
              actionname: 'EWM_PERS_APPT_LIST',
              data: {
                paStateCd: vm.selectedPersonnelAppointmentState,
                paCategoryCd: vm.$_enums.ewm.personnelAppointmentCategory.NEW_HIRE.value,
                baseDt: vm.$refs.dateRangeBox.getStringDate(),
              },
              loading: false,
            };
            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            } else {
              vm.$_Toast(vm.$_msgContents('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
            return rtnData;
          },
        });
      },
      /**
       * @description : 발령 취소
       */
      async onCancel() {
        if (await this.$_Confirm(this.$_msgContents('EWM.MESSAGE.CONFIRM_REVOKE_ORDER', { defaultValue: '발령을 취소하시겠습니까?' }))) {
          const rowKeys = this.$refs.joinAgentGridRef.getGridInstance.getSelectedRowKeys();
          if (rowKeys.length === 0) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
            return;
          }

          const payload = {
            actionname: 'EWM_PERS_APPT_CANCEL',
            data: rowKeys.map(rowKey => {
              return { paId: rowKey };
            }),
          };
          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            this.$refs.joinAgentGridRef.getGridInstance.clearSelection();
            this.$refs.joinAgentGridRef.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
          }
        }
      },
    },
    created() {},
    mounted() {
      this.initCodeMap().then(() => {
        this.dataGrid.columns[2].lookup.dataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_dept']); // 부서
        this.dataGrid.columns[3].lookup.dataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_jikgup']); // 직급
        this.dataGrid.columns[4].lookup.dataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_jikwee']); // 직위
        this.dataGrid.columns[5].lookup.dataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_workgroup']); // 업무그룹
      });

      this.$refs.dateRangeBox.initDate();
      this.onSearch();
    },
  };
</script>

<style lang="scss" scoped></style>
